var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Config } from "../../../public/js/react/utils/config";
const V1_PERSONLIZATION_TOP_LEVEL_FIELDS = [
  "user_id",
  "theme",
  "font_secondary",
  "fontsize_primary",
  "fontsize_secondary",
  "color_name",
  "color_namebar",
  "color_namebar_buttons",
  "color_hype",
  "position_name",
  "position_namebar",
  "bg_page_image",
  "image_logo",
  "profile_embed",
  "bg_image",
  "embed_display_profile",
  "embed_display_secondarynav",
  "embed_color_bodybg",
  "embed_mincolumnwidth",
  "embed_class_secondarynav",
  "embed_url",
  "sharebuttons",
  "custom_css",
  "show_author",
  "personalization_name",
  "public_add_post",
  "remove_duplicate_images",
  "popup_custom_css"
];
function getDefaultPersonalization(tintSlug) {
  return __async(this, null, function* () {
    const url = `${Config.getEnvironmentVariable("API_URL")}/v2/tints/${tintSlug}?include=default_personalization`;
    return fetch(url).then((data) => {
      return data.json().then((jsonData) => {
        if (jsonData.included) {
          return jsonData.included.find((i) => {
            return i.type == "personalization";
          });
        }
      });
    });
  });
}
function getPersonalization(id) {
  return __async(this, null, function* () {
    return fetch(`${Config.getEnvironmentVariable("API_URL")}/v2/personalizations/${id}`).then((data) => {
      return data.json().then((jsonData) => {
        return jsonData.data;
      });
    });
  });
}
export function backportV2PersonlizationtoV1Format(tintSlug, personalizationId) {
  return __async(this, null, function* () {
    const defaultPersonalization = yield getDefaultPersonalization(tintSlug);
    const personalizationOverride = personalizationId ? yield getPersonalization(personalizationId) : void 0;
    const personalization = personalizationOverride ? personalizationOverride : defaultPersonalization;
    if (personalization) {
      return __spreadProps(__spreadValues({
        theme: personalization["attributes"]["theme"],
        personalization_id: personalization["id"]
      }, pick_keys(personalization["attributes"]["data"]["published"], V1_PERSONLIZATION_TOP_LEVEL_FIELDS)), {
        embed_display_profile: JSON.stringify(
          exclude_keys(personalization["attributes"]["data"]["published"], V1_PERSONLIZATION_TOP_LEVEL_FIELDS)
        )
      });
    }
  });
}
function pick_keys(object, keys) {
  return Object.keys(object).filter((key) => keys.includes(key)).reduce((obj, key) => {
    return __spreadProps(__spreadValues({}, obj), {
      [key]: object[key]
    });
  }, {});
}
function exclude_keys(object, keys) {
  return Object.keys(object).filter((key) => !keys.includes(key)).reduce((obj, key) => {
    return __spreadProps(__spreadValues({}, obj), {
      [key]: object[key]
    });
  }, {});
}
