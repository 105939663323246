var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsFiles } from "../build/jsLinks";
import { cssFiles } from "../build/cssLinks";
import { Config } from "../../../public/js/react/utils/config";
import compiledTemplate from "../templates/index.handlebars";
import * as builtinThemes from "../../../application/config/themes.json";
import * as themes from "../../../themes/themes.json";
import { backportV2PersonlizationtoV1Format } from "./personalization";
window.addEventListener("load", function() {
  const API_BASE_URL = Config.getEnvironmentVariable("API_URL");
  const urlParams = new URLSearchParams(document.location.search);
  const username = location.href.match(/(app\/legacy|t)\/([^?\/]+)/)[2];
  const currentHTMLPATH = location.href.match(/(app\/legacy|t)\/([^?]+)/)[1];
  const personalizationId = urlParams.get("personalization_id");
  const forceBundle = urlParams.get("forcebundle");
  const NEW_THEMES = [
    "new_classic",
    "new_atmosphere",
    "video_slider",
    "new_slider",
    "new_square_photo",
    "new_tall_photo",
    "new_gem",
    "new_news",
    "table",
    "email_template_grid",
    "email_template_collage"
  ];
  const noTrack = urlParams.get("notrack") || false;
  if (username === "popup") {
    return;
  }
  fetch(`${API_BASE_URL}/v1/profile/${username}?${urlParams.toString()}`).then((data) => {
    data.json().then((jsonData) => __async(this, null, function* () {
      if (!jsonData || jsonData.error) {
        return;
      }
      const bootstrapData = {};
      const enabled = Object.keys(jsonData.enabled);
      bootstrapData["profile"] = JSON.parse(JSON.stringify(jsonData));
      if (!bootstrapData["profile"]["theme"]) {
        bootstrapData["profile"]["theme"] = "classic_v3";
      }
      var env = Config.isDevEnv() ? "dev" : "prod";
      var envName = Config.getEnvironment();
      var js = [];
      var css = [];
      var bundle = forceBundle ? "bundled" : Config.isDevEnv() ? "unbundled" : "bundled";
      var themeInfo = builtinThemes.default;
      themeInfo["themes"] = themes.default;
      const v2Personalization = yield backportV2PersonlizationtoV1Format(username, personalizationId);
      bootstrapData["profile"] = __spreadValues(__spreadValues({}, bootstrapData["profile"]), v2Personalization);
      if (NEW_THEMES.includes(bootstrapData.profile.theme)) {
        window.location.href = `/tint/tint.html#${username}${document.location.search}`;
      } else {
        js = js.concat(jsFiles[bundle][env]["common"]);
        js = js.concat(jsFiles[bundle][env][bootstrapData["profile"]["theme"]]);
        css = css.concat(cssFiles[bundle][env]["common"]);
        css = css.concat(cssFiles[bundle][env][bootstrapData["profile"]["theme"]]);
        document.open();
        document.write(
          compiledTemplate({
            currentHtmlPath: currentHTMLPATH,
            bootstrapData: JSON.stringify(bootstrapData),
            enabled: JSON.stringify(jsonData.enabled),
            js,
            css,
            config: Config.getEnvironmentVariables(),
            isDev: Config.isDevEnv(),
            themeInfo: JSON.stringify(themeInfo),
            noTrack,
            defaultPersonalizationId: v2Personalization == null ? void 0 : v2Personalization.personalization_id
          })
        );
        document.close();
      }
    }));
  });
});
