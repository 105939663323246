export const jsFiles = {
  unbundled: {
    dev: {
      common: [
        "public/js/jquery-1.11.1.min.js",
        "public/js/bootstrap-3_2_0.js",
        "public/js/bootstrap3-typeahead.min.js",
        "public/js/jquery.lazyload.js",
        "public/js/jquery.nicescroll-3.5.4.ext.js",
        "public/js/jquery.nicescroll-3.5.4.js",
        "public/js/jquery.validate.js",
        "public/js/jquery.validate.custom.validators.js",
        "public/js/modules/constants.js",
        "public/js/jquery.easing.min.js",
        "public/js/jquery.timeago.js",
        "public/js/jquery.timeago.i18n.js",
        "public/js/jquery.timeago.ext.js",
        "public/js/jquery.throttle.min.js",
        "public/js/polyfills.js",
        "public/js/jquery.hypemarks.js",
        "public/js/underscore-1.8.3.js",
        "public/js/json2.js",
        "public/js/md5.js",
        "public/js/backbone-1_1_2.js",
        "public/js/mustache.js",
        "public/js/main.js",
        "public/js/jquery.animate-colors-min.js",
        "public/js/jquery.color.js",
        "public/js/infiniscroll-0_3.js",
        "public/js/css_browser_selector.js",
        "public/js/jquery.imagesloaded.js",
        "public/js/jquery.isotope.2.2.2.min.js",
        "public/js/jquery.cookie.js",
        "public/js/jquery.textfill.js",
        "public/js/jscolor/jscolor.js",
        "public/js/libs/bootstrap-colorpicker/js/bootstrap-colorpicker.min.js",
        "public/js/libs/jquery.tooltipster.min.js",
        "public/js/libs/lazyload.js",
        "public/js/jquery.hotkeys.js",
        "public/js/jquery.tintgrid.js",
        "public/js/jquery.tinttip.js",
        "public/js/jquery.one.min.js",
        "public/js/jquery.loadingindicator.js",
        "public/js/jquery.countdown.js",
        "public/js/bootstrap-slider.js",
        "public/js/jquery.tintslider.js",
        "public/js/moment-2_8_3.js",
        "public/js/daterangepicker-1_3_13/daterangepicker.js",
        "public/js/bootstrap-switch.js",
        "public/js/modules/global.js",
        "public/js/modules/compatible-post-message.js",
        "public/js/modules/baseView.js",
        "public/js/modules/hypeHelper.js",
        "public/js/modules/hypeHelper.isEmbed.js",
        "public/js/build/hypeHelper.generateEmbedBundle.js",
        "public/js/pusher_4.1.0.js",
        "public/js/flickity-2_0_4.min.js",
        "public/js/build/tintViewerRouterBundle.js",
        "public/js/modules/collection.js",
        "public/js/modules/select-personalization-chromecast-modal-view.js",
        "public/js/modules/tint-layout.js",
        "public/js/modules/view-collection.js",
        "public/js/modules/models/request-rights-settings.js",
        "public/js/modules/models/auth-user-model.js",
        "public/js/modules/models/personalization-model.js",
        "public/js/modules/models/personalization-v2-model.js",
        "public/js/modules/jquery.truncate.js",
        "public/js/modules/transactional-save.js",
        "public/js/modules/post-cursor.js",
        "public/js/modules/moderation-view.js",
        "public/js/modules/theme-holder-view.js",
        "public/js/modules/hypeHolderView.js",
        "public/js/modules/loading-state-view.js",
        "public/js/modules/account.js",
        "public/js/modules/hypeView.js",
        "public/js/modules/hype.js",
        "public/js/modules/slideshow-control-view.js",
        "public/js/modules/profile.js",
        "public/js/modules/personalized-theme-stylesheet.js",
        "public/js/modules/post-modal-manager.js",
        "public/js/modules/embed-modal.js",
        "public/js/modules/embed-form.js",
        "public/js/modules/themes.js",
        "public/js/modules/share-tooltip.js",
        "public/js/modules/socialAccountCreator.js",
        "public/js/modules/yt.js",
        "public/js/modules/tag-filter-view.js",
        "public/modules/themes.js",
        "public/modules/theme-coordinator.js",
        "public/modules/realtime-updates.js",
        "public/modules/spotlight.js",
        "public/modules/base-view.js",
        "public/modules/controls.js",
        "public/modules/controls/base-control-view.js",
        "public/modules/controls/button-group.js",
        "public/modules/controls/button-group-list.js",
        "public/modules/controls/button.js",
        "public/modules/controls/checkbox.js",
        "public/modules/controls/dropdown.js",
        "public/modules/controls/input.js",
        "public/modules/controls/section.js",
        "public/modules/controls/slider.js",
        "public/modules/base-theme-view.js",
        "public/modules/base-post-view.js",
        "public/js/webfontloader.js",
        "public/js/flickity-2_0_4.min.js",
        "public/js/modules/waterfall.js",
        "public/js/modules/tintViewerApp.js"
      ],
      hypeTemplate_v2: ["public/js/empty.js"],
      hypeTemplate_photo: ["public/js/empty.js"],
      hypeTemplate_photo_tall: ["public/js/empty.js"],
      hypeTemplate_modern: ["public/js/empty.js"],
      hypeTemplate_news: ["public/js/empty.js"],
      hypeTemplate_tano: ["public/js/empty.js"],
      hypeTemplate_pin: ["public/js/empty.js"],
      hypeTemplate_inverse: ["public/js/empty.js"],
      hypeTemplate_waterfall: ["public/js/empty.js"],
      hypeTemplate_horizontal: ["public/js/modules/slider.js"],
      hypeTemplate_slideshow: ["public/js/modules/slideshow.js"],
      hypeTemplate_realtime: ["public/js/modules/gridTheme.js"],
      hypeTemplate_slideshow_qa: ["public/js/modules/slideshow.js"],
      hypeTemplate_waterfall_events: ["public/js/modules/waterfall.js"],
      hypeTemplate_advertisingUnit: [
        "public/js/modules/advertising-unit-cta-view.js",
        "public/js/modules/advertising-unit-banner-view.js",
        "public/js/modules/advertising-unit-view.js",
        "public/js/modules/editor/background-editor.js"
      ],
      hypeTemplate: ["public/js/empty.js"],
      artsy_v2: [
        "themes/artsy_v2/../classic_v3/theme.js",
        "themes/artsy_v2/../classic_v3/post-view.js",
        "themes/artsy_v2/../classic_v3/tint-flex.js",
        "themes/artsy_v2/../classic_v3/pagination.js",
        "themes/artsy_v2/../classic_v3/jquery.waitforimages.js",
        "themes/artsy_v2/post-view.js",
        "themes/artsy_v2/theme.js"
      ],
      classic_v3: [
        "themes/classic_v3/tint-flex.js",
        "themes/classic_v3/pagination.js",
        "themes/classic_v3/jquery.waitforimages.js",
        "themes/classic_v3/post-view.js",
        "themes/classic_v3/theme.js"
      ],
      gem: ["themes/gem/item-view.js", "themes/gem/theme-style.js", "themes/gem/theme.js"],
      hypeTemplate_atmosphere: [
        "themes/hypeTemplate_atmosphere/vendor/d3.min.js",
        "themes/hypeTemplate_atmosphere/vendor/bezier.js",
        "themes/hypeTemplate_atmosphere/vendor/jquery.truncate.js",
        "themes/hypeTemplate_atmosphere/point-advancer.js",
        "themes/hypeTemplate_atmosphere/blip.js",
        "themes/hypeTemplate_atmosphere/blip-pair.js",
        "themes/hypeTemplate_atmosphere/atmosphere-layout.js",
        "themes/hypeTemplate_atmosphere/atmosphere-post.js",
        "themes/hypeTemplate_atmosphere/atmosphere-theme.js"
      ],
      hypeTemplate_mosaic: ["themes/hypeTemplate_mosaic/build/theme.js"],
      new_atmosphere: ["public/js/empty.js"],
      new_classic: ["public/js/empty.js"],
      new_gem: ["public/js/empty.js"],
      new_news: ["public/js/empty.js"],
      new_slider: ["public/js/empty.js"],
      new_square_photo: ["public/js/empty.js"],
      new_tall_photo: ["public/js/empty.js"],
      news_v2: [
        "themes/news_v2/../classic_v3/theme.js",
        "themes/news_v2/../classic_v3/post-view.js",
        "themes/news_v2/../classic_v3/tint-flex.js",
        "themes/news_v2/../classic_v3/pagination.js",
        "themes/news_v2/../classic_v3/jquery.waitforimages.js",
        "themes/news_v2/post-view.js",
        "themes/news_v2/theme.js"
      ],
      "photo-slideshow": ["themes/photo-slideshow/theme.js", "themes/photo-slideshow/jquery.dotdotdot.min.js"],
      slider_v2: ["themes/slider_v2/build/theme.js"],
      slideshow_v2: ["themes/slideshow_v2/vendor/jquery.textfill.js", "themes/slideshow_v2/theme.js"],
      square_photo_v2: [
        "themes/square_photo_v2/../classic_v3/theme.js",
        "themes/square_photo_v2/../classic_v3/post-view.js",
        "themes/square_photo_v2/../classic_v3/tint-flex.js",
        "themes/square_photo_v2/../classic_v3/pagination.js",
        "themes/square_photo_v2/../classic_v3/jquery.waitforimages.js",
        "themes/square_photo_v2/post-view.js",
        "themes/square_photo_v2/theme.js"
      ],
      testimonials: [
        "themes/testimonials/theme.js",
        "themes/testimonials/post-view.js",
        "themes/testimonials/vendor/jquery.dotdotdot.min.js"
      ],
      ticker: ["themes/ticker/theme.js"]
    }
  },
  bundled: {
    dev: {
      common: ["public/js/build/dev/tint-common.js"],
      hypeTemplate_v2: ["public/js/build/dev/theme-hypeTemplate_v2.js"],
      hypeTemplate_photo: ["public/js/build/dev/theme-hypeTemplate_photo.js"],
      hypeTemplate_photo_tall: ["public/js/build/dev/theme-hypeTemplate_photo_tall.js"],
      hypeTemplate_modern: ["public/js/build/dev/theme-hypeTemplate_modern.js"],
      hypeTemplate_news: ["public/js/build/dev/theme-hypeTemplate_news.js"],
      hypeTemplate_tano: ["public/js/build/dev/theme-hypeTemplate_tano.js"],
      hypeTemplate_pin: ["public/js/build/dev/theme-hypeTemplate_pin.js"],
      hypeTemplate_inverse: ["public/js/build/dev/theme-hypeTemplate_inverse.js"],
      hypeTemplate_waterfall: ["public/js/build/dev/theme-hypeTemplate_waterfall.js"],
      hypeTemplate_horizontal: ["public/js/build/dev/theme-hypeTemplate_horizontal.js"],
      hypeTemplate_slideshow: ["public/js/build/dev/theme-hypeTemplate_slideshow.js"],
      hypeTemplate_realtime: ["public/js/build/dev/theme-hypeTemplate_realtime.js"],
      hypeTemplate_slideshow_qa: ["public/js/build/dev/theme-hypeTemplate_slideshow_qa.js"],
      hypeTemplate_waterfall_events: ["public/js/build/dev/theme-hypeTemplate_waterfall_events.js"],
      hypeTemplate_advertisingUnit: ["public/js/build/dev/theme-hypeTemplate_advertisingUnit.js"],
      hypeTemplate: ["public/js/build/dev/theme-hypeTemplate.js"],
      artsy_v2: ["public/js/build/dev/theme-artsy_v2.js"],
      classic_v3: ["public/js/build/dev/theme-classic_v3.js"],
      gem: ["public/js/build/dev/theme-gem.js"],
      hypeTemplate_atmosphere: ["public/js/build/dev/theme-hypeTemplate_atmosphere.js"],
      hypeTemplate_mosaic: ["public/js/build/dev/theme-hypeTemplate_mosaic.js"],
      new_atmosphere: ["public/js/build/dev/theme-new_atmosphere.js"],
      new_classic: ["public/js/build/dev/theme-new_classic.js"],
      new_gem: ["public/js/build/dev/theme-new_gem.js"],
      new_news: ["public/js/build/dev/theme-new_news.js"],
      new_slider: ["public/js/build/dev/theme-new_slider.js"],
      new_square_photo: ["public/js/build/dev/theme-new_square_photo.js"],
      new_tall_photo: ["public/js/build/dev/theme-new_tall_photo.js"],
      news_v2: ["public/js/build/dev/theme-news_v2.js"],
      "photo-slideshow": ["public/js/build/dev/theme-photo-slideshow.js"],
      slider_v2: ["public/js/build/dev/theme-slider_v2.js"],
      slideshow_v2: ["public/js/build/dev/theme-slideshow_v2.js"],
      square_photo_v2: ["public/js/build/dev/theme-square_photo_v2.js"],
      testimonials: ["public/js/build/dev/theme-testimonials.js"],
      ticker: ["public/js/build/dev/theme-ticker.js"]
    },
    prod: {
      common: [],
      hypeTemplate_v2: ["public/js/build/prod/tint-hypeTemplate_v2.min.js"],
      hypeTemplate_photo: ["public/js/build/prod/tint-hypeTemplate_photo.min.js"],
      hypeTemplate_photo_tall: ["public/js/build/prod/tint-hypeTemplate_photo_tall.min.js"],
      hypeTemplate_modern: ["public/js/build/prod/tint-hypeTemplate_modern.min.js"],
      hypeTemplate_news: ["public/js/build/prod/tint-hypeTemplate_news.min.js"],
      hypeTemplate_tano: ["public/js/build/prod/tint-hypeTemplate_tano.min.js"],
      hypeTemplate_pin: ["public/js/build/prod/tint-hypeTemplate_pin.min.js"],
      hypeTemplate_inverse: ["public/js/build/prod/tint-hypeTemplate_inverse.min.js"],
      hypeTemplate_waterfall: ["public/js/build/prod/tint-hypeTemplate_waterfall.min.js"],
      hypeTemplate_horizontal: ["public/js/build/prod/tint-hypeTemplate_horizontal.min.js"],
      hypeTemplate_slideshow: ["public/js/build/prod/tint-hypeTemplate_slideshow.min.js"],
      hypeTemplate_realtime: ["public/js/build/prod/tint-hypeTemplate_realtime.min.js"],
      hypeTemplate_slideshow_qa: ["public/js/build/prod/tint-hypeTemplate_slideshow_qa.min.js"],
      hypeTemplate_waterfall_events: ["public/js/build/prod/tint-hypeTemplate_waterfall_events.min.js"],
      hypeTemplate_advertisingUnit: ["public/js/build/prod/tint-hypeTemplate_advertisingUnit.min.js"],
      hypeTemplate: ["public/js/build/prod/tint-hypeTemplate.min.js"],
      artsy_v2: ["public/js/build/prod/tint-artsy_v2.min.js"],
      classic_v3: ["public/js/build/prod/tint-classic_v3.min.js"],
      gem: ["public/js/build/prod/tint-gem.min.js"],
      hypeTemplate_atmosphere: ["public/js/build/prod/tint-hypeTemplate_atmosphere.min.js"],
      hypeTemplate_mosaic: ["public/js/build/prod/tint-hypeTemplate_mosaic.min.js"],
      new_atmosphere: ["public/js/build/prod/tint-new_atmosphere.min.js"],
      new_classic: ["public/js/build/prod/tint-new_classic.min.js"],
      new_gem: ["public/js/build/prod/tint-new_gem.min.js"],
      new_news: ["public/js/build/prod/tint-new_news.min.js"],
      new_slider: ["public/js/build/prod/tint-new_slider.min.js"],
      new_square_photo: ["public/js/build/prod/tint-new_square_photo.min.js"],
      new_tall_photo: ["public/js/build/prod/tint-new_tall_photo.min.js"],
      news_v2: ["public/js/build/prod/tint-news_v2.min.js"],
      "photo-slideshow": ["public/js/build/prod/tint-photo-slideshow.min.js"],
      slider_v2: ["public/js/build/prod/tint-slider_v2.min.js"],
      slideshow_v2: ["public/js/build/prod/tint-slideshow_v2.min.js"],
      square_photo_v2: ["public/js/build/prod/tint-square_photo_v2.min.js"],
      testimonials: ["public/js/build/prod/tint-testimonials.min.js"],
      ticker: ["public/js/build/prod/tint-ticker.min.js"]
    }
  }
};
